import { Utils } from './utils';
import { ModalDispatcher } from './components/modalDispatcher';
import { TabsDispatcher } from './components/tabsDispatcher';
import { Swiper, Navigation, Scrollbar, Mousewheel, Keyboard, Pagination, Autoplay } from 'swiper/dist/js/swiper.esm.js';
import anime from 'animejs/lib/anime.es.js';
import SimpleBar from 'simplebar';

Swiper.use([Navigation, Scrollbar, Mousewheel, Keyboard, Pagination, Autoplay]);

document.addEventListener('DOMContentLoaded', () => {



	function checkHeader() {

		let scrollPosition = Math.round(window.scrollY);
		if (scrollPosition > 100){
			document.body.classList.add('sticky');
		} else {
			document.body.classList.remove('sticky');
		}
	}

	window.addEventListener('scroll', Utils.throttle(checkHeader, 25));


	document.body.classList.add('is-loaded');

	setTimeout(function() {
		document.querySelector('.main__bg video') && document.querySelector('.main__bg video').play()
	}, 2900)

	var simpleBar = document.querySelectorAll('.simple_bar');
	[].forEach.call(simpleBar, function(bar) {
		new SimpleBar(bar);
	});


	let navLinks = document.querySelectorAll('[archon-link]');

	[].forEach.call(navLinks, link => {
		link.addEventListener('mousedown', e => {
			e.preventDefault();

			[].forEach.call(navLinks, function(link) {
				link.classList.remove('is-clicked');
			});
			link.classList.add('is-clicked');

			const category = link.getAttribute('href').replace('/', '');
			document.querySelector(`[data-category="${category}"]`).scrollIntoView({
				behavior: 'smooth',
				block: 'center'
			})

		});
	});


	let catalog1 = new Swiper('[slider-catalog-1]', {
		slidesPerView: 4,
		speed: 700,
		spaceBetween: 20,
		mousewheelControl: true,
		freeModeMinimumVelocity: 0.02,
		mousewheelReleaseOnEdges: false,
		freeMode: true,
		mousewheel: {
			invert: true,
			enabled: true,
			forceToAxis: true
		},
		navigation: {
			nextEl: '.slider_catalog__button--next',
			prevEl: '.slider_catalog__button--prev'
		},
		scrollbar: {
			el: '[catalog-scroll-1]',
			draggable: true,
		},
		keyboard: {
			enabled: true,
			onlyInViewport: false,
		},
		breakpoints: {
			500: {
				slidesPerView: 1,
				spaceBetween: 10,
			},
		}

	});



	let catalog2 = new Swiper('[slider-catalog-2]', {
		slidesPerView: 4,
		speed: 700,
		spaceBetween: 20,
		mousewheelControl: true,
		freeModeMinimumVelocity: 0.02,
		mousewheelReleaseOnEdges: false,
		freeMode: true,
		mousewheel: {
			invert: true,
			enabled: true,
			forceToAxis: true
		},
		navigation: {
			nextEl: '.slider_catalog__button--next',
			prevEl: '.slider_catalog__button--prev'
		},
		scrollbar: {
			el: '[catalog-scroll-2]',
			draggable: true,
		},
		keyboard: {
			enabled: true,
			onlyInViewport: false,
		},
		breakpoints: {
			500: {
				slidesPerView: 1,
				spaceBetween: 10,
			},
		}

	});

	let catalog3 = new Swiper('[slider-catalog-3]', {
		slidesPerView: 4,
		speed: 700,
		spaceBetween: 20,
		mousewheelControl: true,
		freeModeMinimumVelocity: 0.02,
		mousewheelReleaseOnEdges: false,
		freeMode: true,
		mousewheel: {
			invert: true,
			enabled: true,
			forceToAxis: true
		},
		navigation: {
			nextEl: '.slider_catalog__button--next',
			prevEl: '.slider_catalog__button--prev'
		},
		scrollbar: {
			el: '[catalog-scroll-3]',
			draggable: true,
		},
		keyboard: {
			enabled: true,
			onlyInViewport: false,
		},
		breakpoints: {
			500: {
				slidesPerView: 1,
				spaceBetween: 10,
			},
		}

	});

	let catalog4 = new Swiper('[slider-catalog-4]', {
		slidesPerView: 4,
		speed: 700,
		spaceBetween: 20,
		mousewheelControl: true,
		freeModeMinimumVelocity: 0.02,
		mousewheelReleaseOnEdges: false,
		freeMode: true,
		mousewheel: {
			invert: true,
			enabled: true,
			forceToAxis: true
		},
		navigation: {
			nextEl: '.slider_catalog__button--next',
			prevEl: '.slider_catalog__button--prev'
		},
		scrollbar: {
			el: '[catalog-scroll-4]',
			draggable: true,
		},
		keyboard: {
			enabled: true,
			onlyInViewport: false,
		},
		breakpoints: {
			500: {
				slidesPerView: 1,
				spaceBetween: 10,
			},
		}

	});

	let catalogTabs = document.querySelectorAll('.catalog_anchors__anchor');
	[].forEach.call(catalogTabs, function(tab) {
		tab.addEventListener('mousedown', function() {
			let lower = tab.querySelector("[slider-lower]").textContent;
			let img = tab.querySelector(".catalog_anchor__img img").getAttribute('src');

			document.querySelector(".slider_top__caption").innerHTML = lower;
			document.querySelector(".slider_top__img img").setAttribute('src', img);

			let title1 = tab.querySelector("[slider-title]").dataset.title1;
			let title2 = tab.querySelector("[slider-title]").dataset.title2;

			let titleLink1 = tab.querySelector("[slider-title]").dataset.titleLink1;
			let titleLink2 = tab.querySelector("[slider-title]").dataset.titleLink2;

			if (title1) {
				document.querySelector('.title_link1').innerHTML = title1;
				document.querySelector('.title_link1').setAttribute('href', titleLink1);
			}
			if (title2) {
				document.querySelector('.slash').innerHTML = "/";
				document.querySelector('.title_link2').innerHTML = title2;
				document.querySelector('.title_link2').setAttribute('href', titleLink2);
			}
		});

	});

	new Swiper('.slider_anchors', {
		slidesPerView: 3,
		speed: 700,
		spaceBetween: 15,
		mousewheelControl: true,
		freeModeMinimumVelocity: 0.02,
		mousewheelReleaseOnEdges: false,
		touchReleaseOnEdges: true,
		freeMode: true,
		mousewheel: {
			invert: true,
			enabled: true,
			forceToAxis: true
		},
		keyboard: {
			enabled: true,
			onlyInViewport: false,
		},
		breakpoints: {
			// 1450: {
			// 	slidesPerView: 2,
			// 	spaceBetween: 30,
			// },
			500: {
				slidesPerView: 1,
				spaceBetween: 10,
			},
		}

	});

	new Swiper('.catalog_top__buttons', {
		slidesPerView: 4,
		speed: 700,
		mousewheelControl: true,
		freeModeMinimumVelocity: 0.02,
		mousewheelReleaseOnEdges: false,
		touchReleaseOnEdges: true,
		watchOverflow: true,
		mousewheel: {
			invert: true,
			enabled: true,
			forceToAxis: true
		},
		keyboard: {
			enabled: true,
			onlyInViewport: false,
		},
		breakpoints: {
			500: {
				slidesPerView: 2,
			},
		}

	});



	new Swiper('.main_slider', {
		slidesPerView: 4,
		speed: 700,
		spaceBetween: 25,
		mousewheelControl: true,
		freeModeMinimumVelocity: 0.02,
		mousewheelReleaseOnEdges: false,
		touchReleaseOnEdges: true,
		freeMode: true,
		mousewheel: {
			invert: true,
			enabled: true,
			forceToAxis: true
		},

		navigation: {
			nextEl: '.stock_top__button--next',
			prevEl: '.stock_top__button--prev',
		},
		scrollbar: {
			el: '.main_slider__scroll',
			draggable: true,
		},
		keyboard: {
			enabled: true,
			onlyInViewport: false,
		},
		breakpoints: {
			1250: {
				slidesPerView: 3,
				spaceBetween: 30,
			},
			500: {
				slidesPerView: 1,
				spaceBetween: 10,
			},
		}

	});



	new Swiper('.service_content_slider', {
		slidesPerView: 1,
		speed: 700,
		spaceBetween: 0,
		mousewheelControl: true,
		freeModeMinimumVelocity: 0.02,
		mousewheelReleaseOnEdges: false,
		touchReleaseOnEdges: true,
		autoplay: {
			delay: 3000,
		},
		mousewheel: {
			invert: true,
			enabled: true,
			forceToAxis: true
		},
		pagination: {
			el: '.service_content_slider__pagination',
			clickable: true,
			type: 'bullets',
		},
		breakpoints: {
			500: {
			},
		}

	});


	new Swiper('.stock_slider', {
		slidesPerView: 4,
		speed: 700,
		spaceBetween: 20,
		mousewheelControl: true,
		freeModeMinimumVelocity: 0.02,
		mousewheelReleaseOnEdges: false,
		touchReleaseOnEdges: true,
		mousewheel: {
			invert: true,
			enabled: true,
			forceToAxis: true
		},
		navigation: {
			nextEl: '.catalog_stock__button--next',
			prevEl: '.catalog_stock__button--prev',
		},
		freeMode: true,
		breakpoints: {
			1250: {
				slidesPerView: 3,
			},
			500: {
				slidesPerView: 1,
			},
		}

	});


	new Swiper('.contract_slider_swiper', {
		slidesPerView: 3,
		speed: 700,
		spaceBetween: 15,
		mousewheelControl: true,
		freeModeMinimumVelocity: 0.02,
		mousewheelReleaseOnEdges: false,
		touchReleaseOnEdges: true,
		mousewheel: {
			invert: true,
			enabled: true,
			forceToAxis: true
		},
		navigation: {
			nextEl: '.contract_slider__button--next',
			prevEl: '.contract_slider__button--prev',
		},

		freeMode: true,
		scrollbar: {
			el: '.contract_slider_swiper__scroll',
			draggable: true,
		},
		breakpoints: {
			500: {
				slidesPerView: 1,
			},
		}

	});

	new Swiper('.scheme_slider', {
		slidesPerView: 4,
		speed: 700,
		spaceBetween: 20,
		mousewheelControl: true,
		freeModeMinimumVelocity: 0.02,
		mousewheelReleaseOnEdges: false,
		touchReleaseOnEdges: true,
		freeMode: true,
		mousewheel: {
			invert: true,
			enabled: true,
			forceToAxis: true
		},
		navigation: {
			nextEl: '.scheme_nav__button--next',
			prevEl: '.scheme_nav__button--prev',
		},
		
		scrollbar: {
			el: '.scheme_slider__scroll',
			draggable: true,
		},
		keyboard: {
			enabled: true,
			onlyInViewport: false,
		},
		breakpoints: {
			1450: {
				spaceBetween: 10,
			},
			1250: {
				slidesPerView: 3,
			},
			500: {
				slidesPerView: 1,
				spaceBetween: 10,
			},
		}

	});

	new Swiper('.service_slider_swiper', {
		slidesPerView: 3,
		speed: 700,
		spaceBetween: 20,
		mousewheelControl: true,
		freeModeMinimumVelocity: 0.02,
		mousewheelReleaseOnEdges: false,
		touchReleaseOnEdges: true,
		freeMode: true,
		mousewheel: {
			invert: true,
			enabled: true,
			forceToAxis: true
		},
		navigation: {
			nextEl: '.service_slider__nav--next',
			prevEl: '.service_slider__nav--prev',
		},
		scrollbar: {
			el: '.service_slider_swiper__scroll',
			draggable: true,
		},
		keyboard: {
			enabled: true,
			onlyInViewport: false,
		},
		breakpoints: {
			1250: {
				slidesPerView: 2,
			},
			500: {
				slidesPerView: 1,
				spaceBetween: 10,
			},
		}

	});






	let inputsForm = document.querySelectorAll('[page-input]');
	[].forEach.call(inputsForm, function(input) {


		input.addEventListener('input', function(event) {

			setTimeout(() => {	
				if(input.value) {
					input.classList.add('is-filled');
				} else {
					input.classList.remove('is-filled');
				}
			});

		});
	});

	let dynamicInputs = document.querySelectorAll('[name="dynamic"]');
	let dynamicOptions = {
		mask: [
		{
			mask: '+{7} (000) 000-00-00',
		},
		{
			mask: /^\S*@?\S*$/,
		}
		]

	};

	[].forEach.call(dynamicInputs, function (dynamicInput) {
		let dynamicMask = IMask(dynamicInput, dynamicOptions);
	});

	var phoneElem = document.querySelectorAll('[name="phone"]'),
	phoneMasks = [];
	[].forEach.call(phoneElem, function(el, i) {
		phoneMasks[i] = new IMask(el, {
			mask: '+{7} (0',
			placeholderChar: ''
		});
		el.addEventListener('focus', function() {
			phoneMasks[i].updateOptions({ lazy: false });
		});
		phoneMasks[i].on('accept', function() {
			var mask = (phoneMasks[i]._unmaskedValue === '7') ? '+{7} (0' : '+{7} (000) 000-00-00',
			placeholderChar = (phoneMasks[i]._unmaskedValue === '7') ? '' : '_';
			phoneMasks[i].updateOptions({ mask: mask, placeholderChar: placeholderChar });
		});
		el.addEventListener('blur', function() {
			if (phoneMasks[i]._unmaskedValue.length < 11) {
				el.value = '';
				phoneMasks[i].updateOptions({ lazy: true });
				phoneMasks[i].value = '';
			}

		});

	});


	let Dropdown = (function() {


		function open(event) {

			let el = event.target.closest('.select');
			let dropDown = event.target.closest('.select_drop');
			let dropDownItem = event.target.closest('.select_drop__item');
			let selects = document.querySelectorAll('.select');


			if (dropDownItem) {

				el.classList.toggle('is-open');

			}

			if (dropDown) {
				return;
			}

			if (!el || !el.classList.contains('is-open')) {
				[].forEach.call(selects, function(select) {
					select.classList.remove('is-open');
				});
			}
			if (el) {
				el.classList.toggle('is-open');
			} else {

				[].forEach.call(selects, function(select) {
					select.classList.remove('is-open');
				});

			}

		}

		function bindEvents() {

			document.addEventListener('click', open);


		}

		function init() {
			bindEvents();
		}
		return {
			init: init
		}

	})();



	let CatalogListEvents = (function() {

		function setOption() {

			let el = event.target.closest('.catalog_list__sort--drop_item');
			if (!el) return;
			let allOptions = document.querySelectorAll('.catalog_list__sort--drop_item'),
			activeOption = [].find.call(allOptions, option => option.classList.contains('is-selected'));

			activeOption.classList.remove('is-selected');			
			el.classList.add('is-selected');

			document.querySelector('.catalog_list__content').classList.add('is-load');
			setTimeout(() => document.querySelector('.catalog_list__content').classList.remove('is-load'), 1500);

		}

		function bindEvents() {

			document.addEventListener('click', setOption);
			document.addEventListener('click', setOption);

		}

		function init() {
			bindEvents();
		}
		return {
			init: init
		}

	})();




	let CatalogSetTabs = (function() {

		function setActiveTab() {

			let el = event.target.closest('.catalog_top_button');
			if (!el) return;
			let allTabs = document.querySelectorAll('.catalog_top_button'),
			activeTab = [].find.call(allTabs, tab => tab.classList.contains('is-clicked'));

			activeTab.classList.remove('is-clicked');			
			el.classList.add('is-clicked');

			activePreloader();
		}
		function switchPage() {
			let el = event.target.closest('.catalog_list_navigation__page');
			if (!el) return;
			let allPages = document.querySelectorAll('.catalog_list_navigation__page'),
			activePage = [].find.call(allPages, page => page.classList.contains('is-active'));

			activePage.classList.remove('is-active');			
			el.classList.add('is-active');

			activePreloader();
		}

		function activePreloader() {
			document.querySelector('.catalog_filter_form').classList.add('is-load');
			setTimeout(() => document.querySelector('.catalog_filter_form').classList.remove('is-load'), 1500);
		}

		function bindEvents() {

			document.addEventListener('click', setActiveTab);
			document.addEventListener('click', switchPage);

		}

		function init() {
			bindEvents();
		}
		return {
			init: init
		}

	})();



	var widgetDispatcher = (function() {

		var widget, widgetBtn, closewidget, hidewidget, overlaywidget, visiblewidget;

		function open() {

			if (widget.classList.contains('is-open')) {
				widget.classList.remove('is-open');
			} else {
				widget.classList.add('is-open');
			}

		}

		function close() {
			widget.classList.remove('is-open');
		}

		function hide() {
			widget.classList.add('is-hidden');
			widget.classList.remove('is-open');
		}

		function visible() {
			widget.classList.remove('is-hidden');
		}

		function overlay() {
			widget.classList.remove('is-open');
		}

		function cacheDom() {
			widget = document.querySelector('.widget');
			widgetBtn = document.querySelector('.widget_btn');
			closewidget = document.querySelector('.close_widget');
			hidewidget = document.querySelector('.hide_widget');
			visiblewidget = document.querySelector('.widget_visible');
			overlaywidget = document.querySelector('.widget_overlay');
		}

		function bindEvents() {

			widgetBtn.addEventListener('click', open);
			closewidget.addEventListener('click', close);
			hidewidget.addEventListener('click', hide);
			overlaywidget.addEventListener('click', overlay);
			visiblewidget.addEventListener('click', visible);

		}

		function init() {
			cacheDom();
			bindEvents();
		}
		return {
			init: init
		}

	})();



	let DropdownSelect = (function() {


		function open(event) {


			let select = event.target.closest('[select]');
			let dropDown = event.target.closest('[select-drop]');
			let dropDownItem = event.target.closest('[select-drop] p');
			let selects = document.querySelectorAll('[select]');


			if (dropDownItem) {
				let title = event.target.closest('[select]').querySelector('[input-caption]');
				let input = event.target.closest('[select]').querySelector('input');

				title.innerHTML = dropDownItem.textContent;
				input.value = dropDownItem.textContent;

				input.dispatchEvent( new Event("change") );

				select.classList.toggle('is-open');
			}

			if (dropDown) {
				return;
			}
			if (!select || !select.classList.contains('is-open')) {

				allRemove(selects);

			}
			if (select) {

				select.classList.toggle('is-open');

			} else {

				allRemove(selects);

			}

		}
		function allRemove(selects) {
			[].forEach.call(selects, function(select) {
				select.classList.remove('is-open');
			});
		}
		function bindEvents() {
			document.addEventListener('click', open);
		}

		function init() {
			bindEvents();
		}
		return {
			init: init
		}

	})();

	DropdownSelect.init();

	CatalogListEvents.init();
	CatalogSetTabs.init();
	widgetDispatcher.init();
	TabsDispatcher.init();
	Dropdown.init();
	ModalDispatcher.init();


	window.addEventListener('submit', function(event) {

		var form = event.target.closest('[data-request]');
		if (!form) return;
		event.preventDefault();
		form.classList.add('on-request');
		var data = new FormData(form);
		fetch("/mailer.php", {
			method: 'POST',
			body: data
		}).then(function(r) {

			return r.text();

		}).then(function(){
			var event =  new Event('mousedown');
			form.classList.remove('on-request');
			event.which = 1;
			if (document.querySelector('#commonModalOverlay').classList.contains('is-active')) {
				document.querySelector('#commonModalOverlay').dispatchEvent(event);
			}
			document.dispatchEvent(new CustomEvent('needModal', {detail: 'accept_feedback'}));

		});
	});
	if (document.querySelector('.widget')) {
		widgetDispatcher.init();    
	}

})



